import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PricingCard from '../../Components/Landing/PricingCard';

// icons
import { BsPen } from 'react-icons/bs';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { SlNotebook } from 'react-icons/sl';
import { VscLaw } from 'react-icons/vsc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { FaBuildingColumns } from 'react-icons/fa6';

const PricingSection = () => {
  const navigate = useNavigate();
  const handleButtonGuidance = () => {
    navigate('/guidance');
  };
  const handleCTAClick = () => {
    navigate('/');
  };

  return (
    <div
      id="about"
      className="font-Apfel min-h-screen flex flex-col justify-around items-center p-12 relative px-6"
    >
      <h1
        className="text-3xl md:text-5xl font-extrabold text-center relative z-20 mb-6"
        dangerouslySetInnerHTML={{
          __html:
            "Trouvez la couverture pensée pour <span class='relative'><span id='handDrawnScribble' class='relative z-10'>vous</span></span>",
        }}
      ></h1>
      <div className="flex xs:flex-col gap-10 xl:flex-row justify-center xl:ml-0 mb-4">
        {/* Formule Green */}
        <PricingCard
          title="Green"
          description="Une formule d'entrée de gamme."
          price="2%"
          offre="1 mission offerte en optant pour l'offre annuelle"
          advantages={[
            { text: 'Génération du cahier des charges ✓', icon: SlNotebook },
            {
              text: 'Séquestration de fonds ✓',
              icon: AiOutlineSafetyCertificate,
            },
            { text: 'Simulation de déclaration ✓', icon: FaBuildingColumns },
            { text: 'Pas de gestion de litiges ✗', icon: VscLaw },
            {
              text: 'Pas de validation du cahier des charges ✗',
              icon: MdOutlineRemoveRedEye,
            },
          ]}
          ctaLabel="Découvrir la formule Green"
          ctaOnClick={handleCTAClick}
          color="#1A4731"
          // background="linear-gradient(to right top, #2b332b, #315932, #328036, #2eaa35, #23d52c)"
        />
        {/* Formule Blue */}
        <PricingCard
          title="Blue"
          description="Une formule adaptée aux besoins habituels."
          price="7%"
          offre="1 mission offerte en optant pour l'offre annuelle"
          advantages={[
            { text: 'Génération du cahier des charges ✓', icon: SlNotebook },
            {
              text: 'Séquestration de fonds ✓',
              icon: AiOutlineSafetyCertificate,
            },
            { text: 'Simulation de déclaration ✓', icon: FaBuildingColumns },
            { text: 'Gestion litiges < 5,000 € ✓', icon: VscLaw },
            {
              text: 'Validation cahier des charges ✓',
              icon: MdOutlineRemoveRedEye,
            },
          ]}
          ctaLabel="Découvrir la formule Blue"
          ctaOnClick={handleCTAClick}
          color="#194D81"
          // background="linear-gradient(to right top, #2b2e33, #2c3e59, #284e81, #235eaa, #236dd5)"
        />
        {/* Formule Purple */}
        <PricingCard
          title="Purple"
          description="Adaptée à certains besoins."
          price="11%"
          offre="2 missions offertes en optant pour l'offre annuelle"
          advantages={[
            { text: 'Génération du cahier des charges ✓', icon: SlNotebook },
            {
              text: 'Séquestration de fonds ✓',
              icon: AiOutlineSafetyCertificate,
            },
            { text: 'Simulation de déclaration ✓', icon: FaBuildingColumns },
            { text: 'Gestion litiges > 5,000 € ✓', icon: VscLaw },
            {
              text: 'Validation cahier des charges ✓',
              icon: MdOutlineRemoveRedEye,
            },
          ]}
          ctaLabel="Découvrir la formule Purple"
          ctaOnClick={handleCTAClick}
          color="#563D7C"
        />
      </div>
      <motion.div
        className="flex flex-col items-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <motion.h2
          className="text-center text-3xl font-bold text-secondary"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Besoin d&apos;orientation?
        </motion.h2>
        <motion.button
          className="px-6 py-3 mt-2 bg-primary text-white rounded-lg focus:outline-none mb-5 text-lg font-bold shadow-lg transition-all duration-300 hover:shadow-none hover:bg-primary-dark hover:text-primary-light"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleButtonGuidance}
        >
          Remplir le formulaire
        </motion.button>
      </motion.div>
    </div>
  );
};

export default PricingSection;
