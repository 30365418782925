import React, { useEffect, useState } from 'react';
import PricingCard from '../Landing/PricingCard';
import { BsPen } from 'react-icons/bs';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { SlNotebook } from 'react-icons/sl';
import { VscLaw } from 'react-icons/vsc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FaBuildingColumns } from 'react-icons/fa6';

interface FormulaCalculatorProps {
  price: string;
  duration: string;
  tarificationHonnete: string;
  delaisCohérents: string;
  confiance: string;
  missions: string;
  techno: string;
  clientTravail: string;
  clientParticulier: string;
  clientLuter: string;
}

const FormulaCalculator: React.FC<FormulaCalculatorProps> = ({
  price,
  duration,
  tarificationHonnete,
  delaisCohérents,
  confiance,
  missions,
  techno,
  clientTravail,
  clientParticulier,
  clientLuter,
}) => {
  const [bestFormula, setBestFormula] = useState<string>('');
  const navigate = useNavigate();
  const handleCTAClick = () => {
    if (bestFormula === 'Green')
      navigate('/mission/new?formula=' + bestFormula);
    if (bestFormula === 'Bleue') navigate('/mission/new?formula=Blue');
    if (bestFormula === 'Purple') navigate('/mission/new?formula=Purple');
  };
  const determineBestFormula = () => {
    if (
      parseInt(price) < 5000 &&
      parseInt(duration) < 30 &&
      tarificationHonnete === 'Oui' &&
      delaisCohérents === 'Oui'
    ) {
      return 'Green';
    } else if (parseInt(duration) > 30 || parseInt(price) > 5000) {
      return 'Bleue';
    } else if (
      tarificationHonnete === 'Non' ||
      delaisCohérents === 'Non' ||
      techno === 'Non'
    ) {
      return 'Purple';
    } else {
      return 'Autre';
    }
  };

  const colorParams: Record<string, any> = {
    Green: {
      title: 'Green',
      description: "Une formule d'entrée de gamme.",
      price: '2%',
      offre: "1 mission offerte en optant pour l'offre annuelle",
      advantages: [
        { text: 'Génération du cahier des charges', icon: SlNotebook },
        { text: 'Séquestration de fonds', icon: AiOutlineSafetyCertificate },
        { text: 'Simulation de déclaration', icon: FaBuildingColumns },
      ],
      ctaLabel: 'Essayer Luter Green',
      background:
        'linear-gradient(to right top, #2b332b, #315932, #328036, #2eaa35, #23d52c)',
      color: '#1A4731',
    },
    Bleue: {
      title: 'Blue',
      description: 'Une formule adaptée aux besoins habituels.',
      price: '7%',
      offre: "1 mission offerte en optant pour l'offre annuelle",
      advantages: [
        { text: 'Génération du cahier des charges', icon: SlNotebook },
        { text: 'Séquestration de fonds', icon: AiOutlineSafetyCertificate },
        { text: 'Simulation de déclaration', icon: FaBuildingColumns },
        { text: 'Gestion litiges < 5k€', icon: VscLaw },
        { text: 'Validation cahier des charges', icon: MdOutlineRemoveRedEye },
      ],
      ctaLabel: 'Essayer Luter Blue',
      background:
        'linear-gradient(to right top, #2b2e33, #2c3e59, #284e81, #235eaa, #236dd5)',
      color: '#194D81',
    },
    Purple: {
      title: 'Purple',
      description: 'Adaptée à certains besoins.',
      price: '11%',
      offre: "2 missions offertes en optant pour l'offre annuelle",
      advantages: [
        { text: 'Génération du cahier des charges', icon: SlNotebook },
        { text: 'Séquestration de fonds', icon: AiOutlineSafetyCertificate },
        { text: 'Simulation de déclaration', icon: FaBuildingColumns },
        { text: 'Gestion litiges > 5k€', icon: VscLaw },
        { text: 'Validation cahier des charges', icon: MdOutlineRemoveRedEye },
      ],
      ctaLabel: 'Essayer Luter Purple',
      background:
        'linear-gradient(to right top, #302b33, #4a3059, #643282, #802fab, #9f23d5)',
      color: '#563D7C',
    },
    Autre: {
      title: '?',
      description: '',
      price: '',
      offre: '',
      advantages: [],
      ctaLabel: '',
      background:
        'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)',
      color: '#000000',
    },
  };

  useEffect(() => {
    console.log('price CALCUL : ', price);
    const newBestFormula = determineBestFormula();
    setBestFormula(newBestFormula);
  }, [price, duration, tarificationHonnete, delaisCohérents]);

  return (
    <div>
      {bestFormula && (
        <PricingCard
          title={colorParams[bestFormula].title}
          description={colorParams[bestFormula].description}
          price={colorParams[bestFormula].price}
          offre={colorParams[bestFormula].offre}
          advantages={colorParams[bestFormula].advantages}
          ctaLabel={colorParams[bestFormula].ctaLabel}
          ctaOnClick={handleCTAClick}
          color={colorParams[bestFormula].color}
        />
      )}
    </div>
  );
};

export default FormulaCalculator;
